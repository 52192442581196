import { Redirect, Route } from "react-router-dom";
import { decodeAuth } from "../../utils/cookie";

function PrivateRoute({ children, ...rest }) {
  const auth = decodeAuth("SID");
  console.log("auth", auth.hasOwnProperty("username") );
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.hasOwnProperty("username") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: "/" },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
