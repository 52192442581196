import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import React from "react";

const MainListItems = (navigation) => {
  return (
    <div>
      <ListItem onClick={() => navigation.push("/dashboard/banner")} button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Banner" />
      </ListItem>
      <ListItem onClick={() => navigation.push("/dashboard/announcement")} button>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Announcement" />
      </ListItem>
      <ListItem onClick={() => navigation.push("/dashboard/news")} button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="News" />
      </ListItem>
    </div>
  );
};

export default MainListItems;
