import React, { lazy, Suspense } from "react";
import {
  Route,
  HashRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import Dashboard from "./Components/Layout/Layout";
import PrivateRoute from "./Components/Layout/PrivateRoutes";

const Banner = lazy(() => import("./pages/banner/Banner"));
const Announcement = lazy(() => import("./pages/announcement/Announcement"));
const News = lazy(() => import("./pages/news/News"));
const Login = lazy(() => import("./pages/login/Login"));

export const Layout = () => (
  <Router>
    <Suspense fallback={<div></div>}>
      <Switch>
        <Route path="/login" component={Login} />
        <Dashboard>
          <Route exact path="/">
            <Redirect to="/banner" />
          </Route>

          <PrivateRoute>
            <Route exact path="/dashboard/banner" component={Banner} />
            <Route
              exact
              path="/dashboard/announcement"
              component={Announcement}
            />
            <Route exact path="/dashboard/news" component={News} />
          </PrivateRoute>
        </Dashboard>
      </Switch>
    </Suspense>
  </Router>
);

export default Layout;
