import Cookies from "js-cookie";
import JwtDecode from "jwt-decode";

export const isAuthenticated = () => {
  Cookies.getJSON("SID");
};

export const PostMessageRN = (data) => {
  if (window.hasOwnProperty("ReactNativeWebView")) {
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }
};

export const saveToken = (token) => {
  if (!token) {
    return;
  }
  Cookies.set("SID", token);
};

export const deleteAllToken = () => {
  Cookies.remove("SID");
};

export const getToken = (typeToken) => {
  const token = Cookies.get(typeToken);
  if (!token) {
    return false;
  }
  return token;
};

export const decodeAuth = () => {
  const ac = getToken("SID");
  if (ac) {
    return JwtDecode(ac);
  }
  return false;
};

export const doEraseAllReload = () => {
  PostMessageRN({ type: "cmd", value: "reload" });
};
